import { gql } from "@apollo/client";

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      userType
    }
  }
`;

export { GET_USER };
