import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import theme from "theme";
import { Route, Switch } from "react-router-dom";

const ConfirmationOrder = lazy(() => import("./confirmation-order"));

const Public = () => {
  return (
    <Suspense
      fallback={
        <div style={{ textAlign: "center", marginTop: "20vh" }}>
          <Spin size="large" style={{ color: theme.colors.primary }} />
        </div>
      }
    >
      <Switch>
        <Route path="/send-guide" component={ConfirmationOrder} />
      </Switch>
    </Suspense>
  );
};

export default Public;
